
import './App.css';

function Form_Submission(props) {

    return(
<div className="green_bgc_denim_txt">
                <div>Thanks for reaching out</div>
                <div>I'll be in touch shortly</div>
            </div>
    )
}

export default Form_Submission;