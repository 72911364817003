function Wordle(props) {
    return (
        <div className="letter-container">
            <div className="letters">W</div>
            <div className="letters">O</div>
            <div className="letters">R</div>
            <div className="letters">R</div>
            <div className="letters">D</div>
        </div>
    );
}

export default Wordle;