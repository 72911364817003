
import './App.css';

function Footer(props) {

    return(
      <header className={"footer"}>
        <a href="https://paoloafenu.ca/">about_me()</a>
        <a href="https://paoloafenu.ca/">my_work()</a>
        <a href="https://paoloafenu.ca/">my_resume()</a>
      </header>
    )
}

export default Footer;